import { Provider } from '@angular/core';
import { payoutDetailsEnabled } from '@bcf-v2-configurators/tokens/betslip/lvbetlv/payout-details-enabled';
import { PAYOUT_DETAILS_ENABLED } from '@bcf-v2-configurators/tokens/betslip/token';
import { forceBackToCurrentLocation } from '@bcf-v2-configurators/tokens/cashier/mobile-web-twa/force-back-to-current-location';
import { FORCE_BACK_TO_CURRENT_LOCATION } from '@bcf-v2-configurators/tokens/cashier/token';

export const settingsProviders: Provider[] = [
  {
    provide: FORCE_BACK_TO_CURRENT_LOCATION,
    useValue: forceBackToCurrentLocation
  },
  {
    provide: PAYOUT_DETAILS_ENABLED,
    useValue: payoutDetailsEnabled
  }
];
